import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { About } from './components/About';
import { WordsFromLetters } from './components/WordsFromLetters';
import { initializeApp } from 'firebase/app';
import { getAnalytics  } from 'firebase/analytics';

import 'bootstrap/dist/css/bootstrap.min.css'
import './custom.css'

export default class App extends Component {
  static displayName = 'Słowa z Literek';

  static firebaseConfig = {
    apiKey: "AIzaSyAPf76NMUUfA2EVTFr3WmxIZ4S0mgwURvM",
    authDomain: "slowazliterek.firebaseapp.com",
    projectId: "slowazliterek",
    storageBucket: "slowazliterek.appspot.com",
    messagingSenderId: "220248049324",
    appId: "1:220248049324:web:4f1e28dd2ee0774895c109",
    measurementId: "G-GRE4PDDQDS"
  };

  static analitycs = null;

  render () {
    const app = initializeApp(App.firebaseConfig);
    App.analytics = getAnalytics(app);

    return (
      <Layout>
        <Route exact path='/' component={WordsFromLetters} />
        <Route path='/about' component={About} />
      </Layout>
    );
  }
}
