import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
//import { initializeApp } from 'firebase/app';
//import { getAnalytics  } from 'firebase/analytics';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

/*const firebaseConfig = {
  apiKey: "AIzaSyAPf76NMUUfA2EVTFr3WmxIZ4S0mgwURvM",
  authDomain: "slowazliterek.firebaseapp.com",
  projectId: "slowazliterek",
  storageBucket: "slowazliterek.appspot.com",
  messagingSenderId: "220248049324",
  appId: "1:220248049324:web:4f1e28dd2ee0774895c109",
  measurementId: "G-GRE4PDDQDS"
};*/

/*const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);*/

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
