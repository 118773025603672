import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';
import './WordsFromLetters.css';

export class WordsFromLetters extends Component {
  static displayName = 'Słowa z literek';

  constructor(props) {
    super(props);

    this.state = { letters: "", preserveLetterCount: false, loading: false, words: [], searchTime: null, wordsSearched: 0, fromCache: false, noResults: false };
    this.handleSubmit.bind(this);
  }

  componentDidMount() {

  }

  static renderResultTable(words) {
    return (
      <div className='result-table-container' style={{'overflowY': 'scroll'}}>
        <table className='table table-striped' aria-labelledby="tabelLabel">
          <thead>
            <tr>
              <th>Słowa, które można zbudować z podanych liter:</th>
            </tr>
          </thead>
          <tbody>
            {words.map(word =>
              <tr key={word}>
                <td><a href={'https://sjp.pl/' + word} target='_blank' rel='noreferrer'>{word}</a></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

    );
  }

  formChange = event => {
    if(event.target.type === 'checkbox')
      this.setState({[event.target.id]: event.target.checked});
    else  
      this.setState({[event.target.id]: event.target.value});
  }

  handleSubmit = async event => {
    event.preventDefault();
    await this.searchWords();
  }

  render() {
    let header =
    <div className='search-container box-shadow'>
        <form onSubmit={this.handleSubmit}>
          <Form.Group controlId="letters">
            <Form.Label>Wpisz litery z których wyszukać słowa</Form.Label>
            <Form.Control type="text" placeholder="Litery...." value={this.state.letters} onChange={this.formChange}></Form.Control>
          </Form.Group>
          <Form.Group controlId="preserveLetterCount">
            <Form.Check type="checkbox" label="Pilnować ilość liter?" value={this.state.preserveLetterCount} onChange={this.formChange}></Form.Check>
          </Form.Group>
          <div className='search-button'>
            <Button className="btn btn-primary" type='submit'>Wyszukaj</Button>
          </div>
        </form>
    </div>

    let loader = this.state.loading ? <div className='loader'>Loading...</div> : null;
    let result = this.state.words.length === 0 ? null : WordsFromLetters.renderResultTable(this.state.words);
    let summary = this.state.searchTime == null || this.state.loading ? null : <div className='summary-text'>Przeszukano {this.state.wordsSearched} słów w czasie {this.state.searchTime}</div>
    let noResults = this.state.noResults ? <div className='loader'>Brak wyników</div> : null;

    return(
      <div>
        {header}
        <div className='result-table'>
          {loader}
          {summary}
          {noResults}
          {result}
        </div>
      </div>
    );
  }

  async searchWords() {
    if(this.state.letters.length == 0)
      return;

    this.setState({loading: true, noResults: false, words: []});
    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({language: 'pl', letters: this.state.letters, preserveLetterCount: this.state.preserveLetterCount})
    }

    const response = await fetch('api/searcher/search-from-letters', requestOptions);
    const data = await response.json();

    this.setState({ words: data.results, searchTime: data.searchTime, wordsSearched: data.wordsSearched, noResults: data.results.length === 0, loading: false });
  }
}
