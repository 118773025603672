import React, { Component } from 'react';

export class About extends Component {
  static displayName = 'O programie';

  render () {
    return (
      <div>
        <h1>Słowa z Literek</h1>
        <p>Prosty program pozwalający na wyświetlenie listy dostępnych słów złożonych z podanych liter.</p>
        <ul>
          <li><a href='https://sjp.pl/slownik/growy/'>Słownik słów</a> jaki został użyty w jego implementacji</li>
          <li>Możliwość wybrania, czy mamy pilnować ilość liter</li>
          <li>Szybki i wydajny algorytm, który potrafi przeszukać <b>3 miliony</b> pozycji w przeciągu kilkudziesięciu sekund</li>
        </ul>
        <p>Program <code>Slowa z Literek</code> został stworzony przez <code>SQuare Solutions - Rafał Palej</code> i nie zezwala na reużywanie <code>API</code>. Jakiekolwiek nadużycia będą skutkowały pernamentnym zablokowaniem adresu IP.</p>
        <p>W przypadku chęci skontaktowania się, proszę robić to poprzez e-mail: <a href='mailto:contact@square.rpalej.pl'>contact@square.rpalej.pl</a> lub poprzez stronę internetową <a href='https://square.rpalej.pl'>SQuare Solutions</a></p>
      </div>
    );
  }
}
